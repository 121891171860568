import logo from "../img/logo.svg";
import React from "react";
import {Menu} from "./Menu";

export const Header = () => {
    return (
        <header className="App-header">
            <img src={logo} className="App-logo" alt="logo"/>
            <Menu />
        </header>
    )
}