import React from "react";
import styled from "styled-components";

const Wrap = styled.div`
  
`

export const Menu = () => {
    return(
        <Wrap>
{/*            <a
                className="App-link"
                href="https://reactjs.org"
                target="_blank"
                rel="noopener noreferrer"
            >
                {t("How it works")}
            </a>
            <a
                className="App-link"
                href="https://reactjs.org"
                target="_blank"
                rel="noopener noreferrer"
            >
                {t("Pricing")}
            </a>*/}
            {/*<LanguageSwitcher />*/}
        </Wrap>

    )
}