import React from 'react';
import {Header} from "./components/Header";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Register} from "./pages/Register";

function App() {
    return (
        <BrowserRouter>
            <Header/>
            <Routes>
                <Route path={'/register'} element={<Register />} />
                <Route path={'/'} element={<Register />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
